import React from 'react';
import './Spinner.css';

const Spinner = () => {
  return (
    <div className="spinner-contenedor">
    <div class="loader"></div>
</div>
  )
}

export default Spinner;