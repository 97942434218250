import Barra1 from './components/Navbar/Navbar';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { HomePage } from './components/HomePage/HomePage';
import { HomeFooter } from './components/HomeFooter/HomeFooter';
import { Contacto } from './components/pages/Contacto/Contacto';
import { AfterSale } from './components/pages/AfterSale/AfterSale';
import { Drivers } from './components/pages/Drivers/Drivers';
import { PageNotFound } from './components/pages/PageNotFound/PageNotFound';
import { Products } from './components/products/productsList'
import { ProductsDetail } from './components/products/productsDetail'
import { Miami } from './components/pages/WhereToBuy/Miami';
import Login from './components/pages/Login/Login';
import { ProductsDetailOffice } from './components/OfficePC/productsDetailOffice';
import { ProductsOfficeShow } from './components/OfficePC/productsListOffice';
import { ProductsInicio } from './components/products/nuevaSeccion';
import ScrollToTop from './components/scrollToTop/scrollToTop';

// PASO 6: IMPORTAR NUESTRO PROVIDER Y ENVOLVER NUESTRA APP ---CONTEXT---dcddsdx

import { PreconfiguradoProvider } from './components/pages/Configurador/ContextConfigurador/PreconfiguradoContext';
import { ListadoProvider } from './components/pages/Configurador/ContextConfigurador/ListadoContext';
import { Detail } from './components/pages/Configurador/Detail';
import Order from './components/pages/Configurador/Order';
import { Configurador } from './components/pages/Configurador/ConfiguradorList';
import { MarcaProcesadorProvider } from './components/pages/Configurador/ContextConfigurador/MarcaProcesadorContext';
import { Testing } from './components/pages/Testing';
import { ProductsDestacados } from './components/ProductosDestacados/productsListDestacados';
import { AccesoriosSueltos } from './components/products/AccesoriosSueltos';
import SearchBar from './components/SearchBar/SearchBar';
import CompraExitosa from './components/pages/CompraExitosa';
import CompraFallida from './components/pages/CompraFallida';
import CompraPendiente from './components/pages/CompraPendiente';



function App() {
  return (
    <PreconfiguradoProvider>
      <ListadoProvider>
        <MarcaProcesadorProvider>
          <BrowserRouter>
            <ScrollToTop>
              <Routes>
                <Route path="/" element={<Detail />} />
                <Route path='*' element={<Navigate to="/PageNotFound" />} />
                <Route path="/PageNotFound" element={<PageNotFound />} />
                <Route path="/afterSale" element={<AfterSale />} />
                <Route path="/contacto" element={<Contacto />} />
                <Route path="/configurador" element={<Configurador />} />
                <Route path="/Detail/:param" element={<Detail />} />
                <Route path="/Order/:param" element={<Order />} />
                <Route path="/Drivers_equipos" element={<Drivers />} />
                <Route path='/contact' element={<Contacto />} />
                <Route path="/notebooks" element={<Products />} />
                <Route path="/pcGamer" element={<ProductsOfficeShow />} />
                <Route path="/pcEscritorio" element={<ProductsDestacados />} />
                <Route path="/marca/:catMarca" element={<ProductsInicio />} />
                <Route path="/testing" element={<Testing />} />
                <Route path="/productos/:filtradoCategoria" element={<AccesoriosSueltos />} />
                <Route path="/productsDetails/:param" element={<ProductsDetail />} />
                <Route path="/pcGamer/:param" element={<ProductsDetailOffice />} />
                <Route path="/miami" element={<Miami />} />
                <Route path="/login" element={<Login />} />
                <Route path="/search" element={<SearchBar />} />

                <Route path="/compra-exitosa" element={<CompraExitosa />} />
                <Route path="/compra-fallida" element={<CompraFallida />} />
                <Route path="/compra-pendiente" element={<CompraPendiente />} />

              </Routes>
              <HomeFooter />
            </ScrollToTop>
          </BrowserRouter>
        </MarcaProcesadorProvider>
      </ListadoProvider>
    </PreconfiguradoProvider>

  );
}

export default App;
